import React from "react"

import HelpIcon from "../../images/help-2.svg"

import styles from "./service-contact-box.module.css"

export default function ServiceContactBox({
  children,
  textHeader,
  textFooter,
  buttonText,
  email,
}) {
  const openBeacon = () => {
    window.Beacon("open")
  }
  return (
    <div className={styles.serviceContactBox}>
      {children}
      <p className={styles.serviceContactBoxText}>{textHeader}</p>
      <p className={styles.serviceContactBoxText}>
        {textFooter}
        <br></br>
        <a href={`mailto:${email}`}>{email}</a>.
      </p>
      <button
        className={styles.serviceContactBoxButton}
        id="open-beacon-chat"
        onClick={openBeacon}
      >
        <img
          src={HelpIcon}
          alt={`Puma FastPay ${buttonText}`}
          className={styles.serviceContactBoxButtonIcon}
        />
        <span className={styles.serviceContactBoxButtonText}>{buttonText}</span>
      </button>
    </div>
  )
}
